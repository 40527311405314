<template>
  <v-app>
    <v-container fluid>
      <div style="max-width: 480px" class="mx-auto">
        <div v-if="isLoading" class="loading">loading...</div>
        <div v-else-if="!uid || !uid.code">
          <div class="error--text text-center fs-18px" style="margin-top: 40vh">
            Không tìm thấy sản phẩm
          </div>
        </div>
        <div v-else>
          <v-card class="mb-3 card-custom-shadow">
            <v-img
              style="background-color: #cccccc6b"
              height="420px"
              :src="uid.image"
            ></v-img>

            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <div class="fs-18px black--text font-weight-medium">
                    {{ uid.name }}
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="primary--text">
                    <span class="fs-16px font-weight-medium">
                      {{ formatNumber(uid.price) }} đ
                    </span>
                    (Giá niêm yết)
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex align-center justify-end">
                    <img
                      :src="require(`@/assets/common/${uid.origin}.png`)"
                      alt=""
                      class="mr-2"
                      style="height: 22px"
                    />
                    {{ uid.origin }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex align-center">
                    <v-icon class="mr-2">mdi-barcode</v-icon>
                    {{ uid.barcode }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- <v-card class="mb-3 card-custom-shadow">
            <v-card-text>
              <div class="fs-16px primary--text text-center font-weight-medium">
                Serial: GOOBY-9722931
              </div>
            </v-card-text>
          </v-card> -->

          <!-- <v-card class="mb-3 card-custom-shadow">
            <v-card-text>
              <v-row dense>
                <v-col cols="6" class="border-right">
                  <v-row dense class="align-center">
                    <v-col cols="3">
                      <v-icon large>mdi-barcode-scan</v-icon>
                    </v-col>
                    <v-col cols="9" class="text-center">
                      <div class="fs-16px font-weight-medium primary--text">
                        1
                      </div>
                      <div>Scan count</div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row dense class="align-center">
                    <v-col cols="3">
                      <v-icon large>mdi-account-multiple</v-icon>
                    </v-col>
                    <v-col cols="9" class="text-center">
                      <div class="fs-16px font-weight-medium primary--text">
                        1
                      </div>
                      <div>Number of Scanners</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card> -->
          <v-card
            class="mb-3 card-custom-shadow"
            v-for="(item, index) in infomations"
            :key="`i_${index}`"
          >
            <v-card-text>
              <div class="fs-16px font-weight-medium primary--text">
                {{ item.title }}
              </div>
              <div class="black--text">
                {{ item.sub_title }}
              </div>
              <div style="white-space: pre-line" v-html="item.content"></div>
            </v-card-text>
          </v-card>
          <template v-if="!me || !me.identity_id">
            <v-card class="mb-3 card-custom-shadow">
              <v-card-text>
                <v-btn block color="warning" :href="`/login?r=${currentLink}`">
                  Đăng nhập
                </v-btn>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="isCorrectCustomer">
            <template v-if="!coords.latitude && !coords.longitude">
              <v-card class="mb-3 card-custom-shadow">
                <v-card-text class="error--text">
                  <div v-if="['Chrome', 'Firefox'].includes(browserName)">
                    Cấp quyền vị trí để kiểm soát sản phẩm!
                  </div>
                  <div v-else>
                    <div>
                      Sử dụng trình duyệt Chrome hoặc Firefox và cấp quyền vị
                      trí để kiểm soát sản phẩm!
                    </div>
                    <div class="d-flex align-center justify-space-between mt-3">
                      <a :href="chromeLink" target="_blank"> Tải Chrome </a>
                      <a :href="firefoxLink" target="_blank"> Tải Firefox </a>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </template>

            <template v-else>
              <v-card class="mb-3 card-custom-shadow">
                <v-card-text>
                  <v-simple-table class="table-padding-2 table-h-36">
                    <template v-slot:default>
                      <tbody>
                        <tr class="font-weight-medium error--text">
                          <td style="width: 105px">NPP:</td>
                          <td>
                            {{ uid.distributor_name }}
                          </td>
                        </tr>
                        <tr>
                          <td>Ngày cấp:</td>
                          <td>
                            {{
                              uid.last_assigned_at
                                ? formatDateTime(uid.last_assigned_at)
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Code:</td>
                          <td>{{ uid.code }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>

              <v-card class="mb-3 card-custom-shadow">
                <v-card-text>
                  <v-simple-table class="table-padding-2 table-h-36">
                    <template v-slot:default>
                      <thead class="v-data-table-header">
                        <tr>
                          <th
                            role="columnheader"
                            class="text-center"
                            style="width: 105px"
                          >
                            Thời gian
                          </th>
                          <th role="columnheader" class="text-center">
                            Tài khoản
                          </th>
                          <th role="columnheader" class="text-center">
                            Vị trí
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="text-center"
                          v-for="(log, index) in logs"
                          :key="`l_${index}`"
                        >
                          <td>
                            {{ formatDateTime(log.created_at) }}
                          </td>
                          <td>
                            {{ log.identity_name }}
                          </td>
                          <td>
                            <a
                              :href="`https://www.google.com/maps/search/?api=1&query=${log.latitude},${log.longitude}`"
                              target="_blank"
                            >
                              Bản đồ
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </template>
          </template>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "DcCheck",
  data: () => ({
    isLoading: true,
    uid: {},
    me: {},
    logs: [],
    coords: {},
    currentLink: null,
  }),
  computed: {
    chromeLink() {
      if (this.osName == "iOS") {
        return "https://apps.apple.com/vn/app/google-chrome/id535886823?l=vi";
      } else if (this.osName == "Android") {
        return "https://play.google.com/store/apps/details?id=com.android.chrome&hl=vi";
      } else {
        return "https://www.google.com/intl/vi/chrome/";
      }
    },
    firefoxLink() {
      if (this.osName == "iOS") {
        return "https://apps.apple.com/vn/app/firefox-private-safe-browser/id989804926?l=vi";
      } else if (this.osName == "Android") {
        return "https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=vi";
      } else {
        return "https://www.mozilla.org/vi/firefox/new/";
      }
    },
    isCorrectCustomer() {
      if (!this.uid || !this.uid.cid) {
        return false;
      }

      if (!this.me || !this.me.customer || !this.me.customer.id) {
        return false;
      }

      const id_customer = this.decodeNumber(this.uid.cid);

      return this.me.customer.id == id_customer;
    },
    infomations() {
      if (this.isCorrectCustomer) {
        return [...this.uid.infomations].filter((info) =>
          [2, 3].includes(info.display_type)
        );
      }
      return [...this.uid.infomations].filter((info) =>
        [1, 3].includes(info.display_type)
      );
    },
  },
  async mounted() {
    await this.getItem();
    await this.checkLogin();
    this.currentLink = window.location.href;
  },
  methods: {
    async getLocation() {
      const self = this;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const coords = { latitude, longitude };
            this.coords = { ...coords };
            if (this.me && this.me.identity_id) {
              this.getLog();
            }
          },
          (err) => {
            switch (err.code) {
              case err.PERMISSION_DENIED:
                self.$vToastify.warning(
                  this.$t("Cấp quyền vị trí để kiểm soát sản phẩm!")
                );
                break;
              case err.POSITION_UNAVAILABLE:
                self.$vToastify.warning(this.$t("Location unavailable"));
                break;
              case err.TIMEOUT:
                self.$vToastify.warning(this.$t("Location timeout"));
                break;
              case err.UNKNOWN_ERROR:
                self.$vToastify.warning("UNKNOWN_ERROR");
                break;
            }
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        this.$vToastify.warning("Trình duyệt không hỗ trợ vị trí");
      }
    },
    async checkLogin() {
      const sct = localStorage.getItem("_sct");
      if (!sct) {
        return false;
      }

      const { data } = await httpClient.post("/me");
      if (data) {
        this.me = { ...data };
        await this.getLocation();
      }
    },
    async getLog() {
      const { data } = await httpClient.post("/dc-get-item-log", {
        code: this.uid.code,
        ...this.coords,
      });
      this.logs = { ...data };
    },
    async getItem() {
      const { code } = this.$route.params;
      if (!code) {
        this.isLoading = false;
        return false;
      }

      try {
        const { data } = await httpClient.post("/dc-get-item", {
          code,
        });
        this.uid = { ...data };

        /* if (
          data &&
          data.code &&
          this.me &&
          this.me.identity_id &&
          this.coords &&
          this.coords.latitude &&
          this.coords.longitude
        ) {
          this.getLog();
        } */
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
